/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import thunk from 'redux-thunk'

import meReducer from '@/common/reducers/meReducer'
import authReducer from '@/modules/auth/reducers/authReducer'
import userReducer from '@/modules/authorization/reducers/userReducer'
import doctorReducer from '@/modules/doctor/reducers/doctorReducer'
import infoReducer from '@/modules/info/reducers/infoReducer'
import medicalReducer from '@/modules/medical-record/reducers/medicalReducer'
import organizationReducer from '@/modules/organization/reducers/organizationReducer'

const usersFilter = createBlacklistFilter('users', ['items'])

const projectFilter = createBlacklistFilter('project', ['items'])

const persistConfig = {
  key: 'clinic',
  storage,
  whitelist: ['auth', 'users'],
  transforms: [usersFilter, projectFilter]
}

const rootReducer = combineReducers({
  auth: authReducer,
  me: meReducer,
  users: userReducer,
  doctor: doctorReducer,
  medical: medicalReducer,
  infomation: infoReducer,
  organization: organizationReducer
  // App
})

const middlewares: any = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
